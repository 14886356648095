<template>
  <div id="user_form" v-if="discountData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ discountData.id == 0 ? "Add" : "Edit" }} Discount
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">

            <b-col md="6">
              <b-form-group label="Discount Name" label-for="name" label-cols-md="4" class="required">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="discountData.name" :state="errors.length > 0 ? false : null"
                    placeholder="Discount Name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Discount Code" label-for="code" label-cols-md="4"
                :class="discountData.type === 'referral' ? 'required' : discountData.type === 'individual' ? 'required' : ''">
                <validation-provider #default="{ errors }" name="code"
                  :rules="discountData.type === 'referral' ? 'required' : discountData.type === 'individual' ? 'required' : ''">
                  <b-form-input id="code" v-model="discountData.code" :state="errors.length > 0 ? false : null"
                    placeholder="Discount Code" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Discount Type -->
            <b-col cols="12" md="6">
              <b-form-group label="Discount Type" label-for="type" label-cols-md="4" class="required">
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <div class="form-col-select">
                    <v-select :state="errors.length > 0 ? false : null" id="type" background-color="white"
                      v-model="discountData.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="discountTypeOptions" :clearable="false" label="label" :reduce="(option) => option.value"
                      placeholder="Select discount type" />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Discount Value -->
            <b-col cols="12" md="6">
              <b-form-group label="Discount Value Type" label-for="discount_type" label-cols-md="4" class="required">
                <validation-provider #default="{ errors }" name="discount_type" rules="required">
                  <div class="form-col-select">
                    <v-select :state="errors.length > 0 ? false : null" id="discount_type" background-color="white"
                      v-model="discountData.discount_type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="discountValueOptions" :clearable="false" label="label"
                      :reduce="(option) => option.value" placeholder="Select discount value" />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Discount Value" label-for="discount_value" label-cols-md="4" class="required">
                <validation-provider #default="{ errors }" name="discount_value" rules="required">
                  <b-form-input id="discount_value" v-model="discountData.discount_value"
                    :state="errors.length > 0 ? false : null" placeholder="Discount Value" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Minimum Spend" label-for="minimum_spend" label-cols-md="4" class="required">
                <validation-provider #default="{ errors }" name="minimum_spend" rules="required">
                  <b-form-input id="minimum_spend" v-model="discountData.minimum_spend"
                    :state="errors.length > 0 ? false : null" placeholder="Minimum Spend" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Usage Limit" label-for="usage_limit" label-cols-md="4" class="">
                <validation-provider #default="{ errors }" name="usage_limit" rules="">
                  <b-form-input id="usage_limit" v-model="discountData.usage_limit"
                    :state="errors.length > 0 ? false : null" placeholder="Usage Limit" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Usage Count" label-for="usage_count" label-cols-md="4" class="required">
                <validation-provider #default="{ errors }" name="usage_count" rules="required">
                  <b-form-input id="usage_count" v-model="discountData.usage_count"
                    :state="errors.length > 0 ? false : null" placeholder="Usage Count" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="discountData.type === 'referral' || discountData.type === 'individual'">
              <b-form-group label="Valid Days" label-for="valid_days" label-cols-md="4"
                :class="discountData.type === 'individual' ? 'required' : ''">
                <validation-provider #default="{ errors }" name="valid_days"
                  :rules="discountData.type === 'individual' ? 'required' : ''">
                  <b-form-input id="valid_days" v-model="discountData.valid_days"
                    :state="errors.length > 0 ? false : null" placeholder="Valid Days" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- v-if="discountData.type === 'individual'" -->
            <b-col md="12">
              <b-form-group label="Customers" label-for="customers" label-cols-md="4"
                :class="discountData.type === 'individual' ? 'required' : ''">
                <validation-provider #default="{ errors }" name="customers"
                  :rules="discountData.type === 'individual' ? 'required' : ''">
                  <div class="form-col-select">
                    <v-select :state="errors.length > 0 ? false : null" id="type" background-color="white"
                      v-model="discountData.customers" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customerOptions" :clearable="true"
                      :get-option-label="option => `${option.id} - ${option.first_name} ${option.last_name}`"
                      :reduce="(option) => option.id" placeholder="Select customers" multiple>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <span>{{ option.id }} - {{ option.first_name }} {{ option.last_name }}</span>
                        </div>
                      </template>
                    </v-select>
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label-for="is_active">
                <validation-provider #default="{ errors }" name="is_active" rules="required">
                  <b-form-checkbox :checked="discountData.is_active ? true : false" @change="
                    discountData.is_active = !discountData.is_active
                    " class="custom-control-success" name="check-button" switch inline />
                  {{
                    discountData.is_active
                      ? "Active"
                      : "Active"
                  }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label-for="has_day_range">
                <validation-provider #default="{ errors }" name="has_day_range" rules="required">
                  <b-form-checkbox :checked="discountData.has_day_range ? true : false" @change="
                    discountData.has_day_range = !discountData.has_day_range
                    " class="custom-control-success" name="check-button" switch inline />
                  {{
                    discountData.has_day_range
                      ? "Promotion Discount"
                      : "Promotion Discount"
                  }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Start Date -->
            <b-col cols="12" md="6" v-if="discountData.has_day_range">
              <validation-provider #default="validationContext" name="Promotion Start Date" rules="required">
                <b-form-group label-cols-sm="4" label-cols="12" class="required" label="Promotion Start Date"
                  label-for="start_date">
                  <flat-pickr v-model="discountData.start_date" class="form-control" :config="futureDateConfig"
                    placeholder="YYYY-MM-DD" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: End Date -->
            <b-col cols="12" md="6" v-if="discountData.has_day_range">
              <validation-provider #default="validationContext" name="Promotion End Date" rules="required">
                <b-form-group label-cols-sm="4" label-cols="12" class="required" label="Promotion End Date"
                  label-for="start_date">
                  <flat-pickr v-model="discountData.end_date" class="form-control" :config="futureDateConfig"
                    placeholder="YYYY-MM-DD" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-sm-2 mobile-w100"
              type="submit">
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide"
              class="mt-1 mt-sm-0 mobile-w100">
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  methods: {
    hide() {
      this.$router.replace("/discount/list");
    },
    onSubmit() {
      console.log("submit data", this.discountData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (this.discountData.has_day_range === 0 || this.discountData.has_day_range === false || this.discountData.has_day_range === undefined) {
          this.discountData.start_date = null;
          this.discountData.end_date = null;
        }

        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-discount/addDiscount", this.discountData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/discount/list");
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    discountData: {
      type: Object,
      required: true,
    },
    discountTypeOptions: {
      type: Array,
      required: true,
    },
    discountValueOptions: {
      type: Array,
      required: true,
    },
    customerOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.discountData);

    const loading = ref(false);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetdiscountData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetdiscountData
    );

    onMounted(() => { });

    return {
      loading,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetdiscountData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
