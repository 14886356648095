<template>
  <component :is="discountData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="discountData === undefined">
      <h4 class="alert-heading">Error fetching discount data</h4>
      <div class="alert-body">
        No discount found with this discount id. Check
        <b-link class="alert-link" :to="{ name: 'discount-list' }">
          Discount List
        </b-link>
        for other discounts.
      </div>
    </b-alert>

    <b-tabs v-if="discountData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Discount</span>
        </template>
        <discounts-tab-information :discount-data="discountData" :discount-type-options="discountTypeOptions"
          :discount-value-options="discountValueOptions" :customer-options="customerOptions" @refetch-data="refetchData"
          class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import discountStoreModule from "../discountStoreModule";
import DiscountsTabInformation from "./DiscountsTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    DiscountsTabInformation,
  },
  setup() {
    const discountData = ref(null);

    const discountTypeOptions = ref([
      { label: "Referral", value: "referral" },
      { label: "Promotional", value: "promotional" },
      { label: "Individual", value: "individual" },
    ]);
    const discountValueOptions = ref([
      { label: "Percentage", value: "percentage" },
      { label: "Fixed", value: "fixed" },
    ]);
    const PRODUCT_CATEGORY_APP_STORE_MODULE_NAME = "app-discount";
    const customerOptions = ref([]);

    const discount = {
      id: 0,
      name: null,
      code: null,
      type: null,
      discount_type: null,
      discount_value: null,
      minimum_spend: null,
      usage_limit: null,
      usage_count: 0,
      valid_days: null,
      has_day_range: 0,
      start_date: null,
      end_date: null,
      is_active: 1,
      customers: [],
    };

    // Register module
    if (!store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
      store.registerModule(
        PRODUCT_CATEGORY_APP_STORE_MODULE_NAME,
        discountStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "discount-create") {
        discountData.value = discount;
      } else {
        store
          .dispatch("app-discount/fetchDiscount", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            console.log("get discount categpry response", response);
            discountData.value = response.data.discount;
          })
          .catch((error) => {
            console.log("error when fetching discount", error);
            if (error.response.status === 404) {
              discountData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });


    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          customers: true,
        })
        .then((response) => {
          customerOptions.value = response.data.customers;
        });
    };

    return {
      discountData,
      refetchData,
      refetchOption,
      discountTypeOptions,
      discountValueOptions,
      customerOptions,
    };
  },
};
</script>

<style></style>
