import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDiscounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/discounts/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDiscount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/discounts`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addDiscount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/discounts", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteDiscount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/discounts", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/discounts/discount_status`, { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
